/* Global styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', 'Poppins', sans-serif;
}

body {
  background-color: #333;
  color: #fff;
}
