li {
  list-style: none;
}

.container {
  /* border: 1px green solid; */
  display: flex;
  flex-direction: column;
}

.body {
  /* border: 1px green solid; */
  margin-top: 10vh;
  display: flex;
  flex-direction: row;
}

.side-menu {
  font-size: large;
  /* box-shadow: 5px 5px 10px rgba(249, 246, 246, 1); */
  border-right: 1px wheat solid;
  border-radius: 5px;
  /* border: 1px red solid; */
  padding: 1rem;
  width: 10%;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-right: 1rem;
}

.side-menu a {
  cursor: pointer;
  /* border: 1px red solid; */
  padding: 2%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: inherit;
  width: 100%;
  border-radius: 5px;
}

.side-menu a:hover {
  cursor: pointer;
  color: #009800;
  background-color: white;
  /* background-color: #009800; */
  /* border-bottom: 1px white solid; */
}

.body-app {
  /* border: 1px blue solid; */
  /* padding: 1rem; */
  width: 90%;
  min-height: 800px;
}


@media (max-width: 1390px) {
  .body {
    /* border: 1px red solid; */
    flex-direction: column;
    min-height: 800px;
  }
  .body-app {
    width: 100%;
    font-size: larger;
    padding: 0 1rem;
  }
  .side-menu {
    font-size: x-large;
    /* box-shadow: 5px 5px 10px rgba(249, 246, 246, 1); */
    border-right: none;
    border-bottom: 1px wheat solid;
    padding: 1rem;
    width: 100%;
    min-height: 10%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .side-menu a {
    color: #333;
    background-color: white;
    border: 1px #fff solid;
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    justify-content: center;
    /* border: 1px red solid; */
  }
  .side-menu a label {
    display: none;
  }
}
