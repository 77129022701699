.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 5%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  background-color: #3b3d3f;
  border-radius: 5px;
  border-bottom: 1px wheat solid;
}

.header-login {
  position: fixed;
  top: 0;
  left: 0;
  height: 5%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  background-color: rgb(255, 255, 255, 0.05);
  border-radius: 5px;
}

.user-login {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.user-login div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.bt-logout {
  padding: 1rem;
  border-radius: 15px;
}

.bt-logout:hover,
.bt-logout:hover div,
.bt-logout:hover label {
  cursor: pointer;
  color: #282c34;
  background-color: white;
}

.logo {
  cursor: pointer;
}

.logo:hover {
  scale: 105%;
}
