.container-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-notification {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  /* border: 1px red solid; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 5rem;
}

.popup {
  background-color: #282c34;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.notification-popup {
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.buttons-popup {
  margin-top: 20px;
}

.confirmDeleteButton-popup {
  background-color: red;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.confirmButton-popup {
  background-color: blue;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.cancelButton-popup {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancelButton-popup:hover {
  background-color: white;
  color: #333;
}

.confirmDeleteButton-popup:hover {
 background-color:white;
 color: red;
}

.confirmButton-popup:hover {
  background-color:white;
  color: blue;
 }
 