.div-create {
  /* border: 1px green solid; */
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.div-desc-input {
  display: flex;
  flex-direction: row;
  gap: 1px;
  width: 100%;
  margin-bottom: 1rem;
}
.div-desc-input label {
  width: 20%;
  font-weight: bold;
}

.div-desc-input input {
  width: 80%;
  font-size: large;
  border: none;
  border-bottom: 1px white solid;
  outline: none;
  color: white;
  background-color: transparent;
}

.div-desc-input select,
.div-desc-input option {
  width: 80%;
  font-size: large;
  border: none;
  border-bottom: 1px white solid;
  outline: none;
  color: white;
  background-color: #333;
}

@media (max-width: 1390px) {
  .div-desc-input {
    flex-direction: column;
  }
}
