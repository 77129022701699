.resource {
  display: flex;
  flex-direction: column;
  /* border: 1px red solid; */
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}

.div-item {
  /* border: 1px green solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* min-height: 600px; */
}
.ul-list {
  /* border: 1px pink solid; */
  width: 100%;
  /* min-height: 500px; */
  padding: 1rem;
}

.li-item {
  /* border: 1px blue solid; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 1px white solid;
  border-right: 1px white solid;
  border-bottom: 1px white solid;
  border-radius: 5px;
}
.li-item:hover {
  background-color: #282c34;
}

.li-title {
  padding: 1rem 0rem 1rem 1rem;
  background-color: gray;
   /* border: 1px blue solid; */
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-left: 1px white solid;
   border-right: 1px white solid;
   border-top: 1px white solid;
   border-radius: 5px;
}

.li-item div,
.li-item a {
  text-decoration: none;
  /* border: 1px red solid; */
  padding: 1rem;
}

.item-date {
  cursor: default;
  width: 10%;
}

.item-description {
  display: flex;
  flex-direction: column;
  cursor: default;
  width: 55%;
}
.item-description div {
  font-size: small;
  padding: 5px;
  display: flex;
}
.item-value {
  cursor: default;
  width: 10%;
}
.item-status {
  cursor: default;
  width: 5%;  
}

.item-details {
  cursor: pointer;
  width: 5%;
  text-decoration: none;
  color: inherit;
}
.item-details:hover {
  background-color: gray;
}

.item-remove {
  border-radius: 5%;
  text-align: center;
  width: 5%;
  cursor: pointer;  
  color: white;
}

.item-remove:hover {
  scale: 90%;
  color: red;
  background-color: white;
}

.li-item-m {
  display: none;
}
.div-none {
  display: none;
}

.div-buttons {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.button-add {
  cursor: pointer;
  background-color: green;
  color: white;
  border-radius: 15%;
  padding: 1rem;
}

.button-add:hover {
  background-color: white;
  color: green;
}

.button-back {
  cursor: pointer;
  color: inherit;
  background-color: gray;
  border-radius: 15%;
  padding: 1rem;
}

.button-back:hover {
  background-color: white;
  color: red;
}

.button-save {
  cursor: pointer;
  border: none;
  color: inherit;
  background-color: blue;
  border-radius: 15%;
  padding: 1rem;
  text-align: center;
}

.button-save:hover,
.button-save:hover div {
  background-color: white;
  color: blue;
}
.div-moblie {
  display: none;
}

.div-list-footer {
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items:  center;
}

.div-list-footer span,
.div-list-footer div {
  cursor: pointer;
  text-align: center;
  width: 5%;
  padding: 1rem;
  border-radius: 5%;
}
.div-list-footer span:hover {
  background-color: gray;
}
.div-list-footer-input input {
  font-size: large;
  text-align: right;
  font-weight: bold;
  color: white;
  background-color: transparent;
  border: none;
  border-bottom: 1px wheat solid;
  border-radius: 5px;
  outline: none;
}

.div-list-footer-input {
  font-size: large;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  outline: none;
  border: none;
}

.info {
  border: 1px wheat solid;
  border-radius: 5px;
  background-color: #282c34;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1rem;
}

.item-bank-account {
  height: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px wheat solid;
  background-color: #282c34;
  border-radius: 5px;
}

/* responsividade  */
@media (max-width: 1390px) {
  .div-moblie {
    border-bottom: 1px white solid;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .ul-list a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }
  .div-moblie:hover {
    background-color: gray;
  }

  .li-item,
  .li-title {
    display: none;
  }
  
  .li-item-m {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .li-item-m div {
    font-size: small;
    /* border: 1px green solid; */
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 1rem;
    text-decoration: none;
    color: inherit;
  }
  .li-m-p1 {
    /* border-bottom: 1px white solid; */
    /* background-color: green; */
    font-weight: bold;
  }
  .item-description-m {
    width: 85%;
  }

}
