.login-container {
  /* border: 5px white solid; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.form-login {
  width: 50%;
  /* border: 1px red solid; */
  display: flex;
  justify-content: center;
  padding: 1rem;
  align-items: center;
  min-height: 800px;
}

.div-inputs {
  padding: 5rem;
  /* border: 1px pink solid; */
  box-shadow: 5px 5px 10px rgba(249, 246, 246, 0.1);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.div-input-login {
  /* border: 1px green solid; */
  padding: 1rem;
}

.div-input-login div input {
  border: none;
  outline: none;
  color: white;
  background-color: transparent;
}

.div-input-login div {
  display: flex;
  justify-content: space-between;
  font-size: medium;
  padding: 0.5rem;
  border-bottom: 1px white solid;
  border-radius: 5px;
}

.div-input-login div:hover {
  border-bottom: 1px #0f0 solid;
  scale: 101%;
}

/* .div-input-login div:focus-within {  
  border-bottom: 1px #0f0 solid;
} */
.bt-login-disabled{
  border: none;
  cursor: pointer;
  font-size: larger;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 15px;
  color: gray;
  background-color: rgb(53, 53, 53);
}

.bt-login{
  border: none;
  cursor: pointer;
  /* border: 1px blue solid;   */
  padding: 0.5rem;
  border-radius: 15px;
  background-color: #009800;
  color: white;
  font-size: larger;
  font-weight: bold;
}
.bt-login:hover {
  color: #0f0;
  background-color: white;
  scale: 101%;
}

.banner-img-login {
  width: 50%;
  height: auto;
  /* border: 1px violet solid; */
}

@media (max-width: 980px) {
  .banner-img-login {
    display: none;
  }
  .form-login {
    margin-left: 2vh;
    width: 100%;
  }
  .login-container {
    min-height: 800px;
  }

  .div-inputs h1 {
    text-align: center;
  }
}
